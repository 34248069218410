import { Flex, Spinner } from "@radix-ui/themes";
import { useGetCompanyStatsQuery } from "../data/graphql/generated/graphql";
import { CompanyStatsContext } from "./CompanyStatsContext";

type CompanyStatsProviderProps = {
  nbFiles: number;
  nbVendors: number;
  nbSubscriptions: number;
  children: React.ReactNode;
};

function CompanyStatsProvider({
  nbFiles,
  nbVendors,
  nbSubscriptions,
  children,
}: CompanyStatsProviderProps) {
  return (
    <CompanyStatsContext.Provider
      value={{ nbFiles, nbVendors, nbSubscriptions }}
    >
      {children}
    </CompanyStatsContext.Provider>
  );
}

type CompanyStatsGuardProps = {
  children: React.ReactNode;
};

export default function CompanyStatsGuard({
  children,
}: CompanyStatsGuardProps) {
  const { data, loading, error } = useGetCompanyStatsQuery();

  if (loading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="3" />
      </Flex>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <CompanyStatsProvider
      nbFiles={data?.files.aggregate?.count || 0}
      nbVendors={data?.vendors.aggregate?.count || 0}
      nbSubscriptions={data?.subscriptions.aggregate?.count || 0}
    >
      {children}
    </CompanyStatsProvider>
  );
}
