import { Button } from "@radix-ui/themes";
import { Icon } from "@phosphor-icons/react";
import clsx from "clsx";
import { Link } from "react-router";
import style from "./style.module.css";

type SidebarButtonProps = {
  active?: boolean;
  to: string;
  icon: Icon;
  children: React.ReactNode;
};

export default function SidebarButton({
  active = false,
  to,
  icon: ButtonIcon,
  children,
}: SidebarButtonProps) {
  const content = (
    <>
      <ButtonIcon size="18px" />
      {children}
    </>
  );

  return (
    <Button
      size="3"
      style={{
        justifyContent: "flex-start",
      }}
      className={clsx({ [style.Inactive]: !active })}
      variant={active ? "soft" : "ghost"}
      color={active ? "grass" : "gray"}
      asChild
    >
      <Link to={to}>{content}</Link>
    </Button>
  );
}
