import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import { useCurrentUser } from "../auth/user-context";
import { captureException, captureMessage } from "../services/sentry";

type AuthApolloProviderProps = {
  children: React.ReactNode;
};

export default function AuthApolloProvider({
  children,
}: AuthApolloProviderProps) {
  const { login } = useCurrentUser();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (import.meta.env.PROD) {
      if (networkError) {
        captureException(networkError);
      } else if (graphQLErrors?.length) {
        for (const err of graphQLErrors) {
          captureMessage(err.message);
        }
      }
    }

    if (
      graphQLErrors?.length &&
      graphQLErrors[0].extensions?.code === "access-denied"
    ) {
      console.error("User has been logged out");
      login();
    }
  });

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeError: true,
    },
  });

  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_APP_GRAPHQL_API_URI,
    credentials: "include",
  });

  const client = new ApolloClient({
    link: import.meta.env.PROD
      ? from([errorLink, sentryLink, httpLink])
      : from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={client}>
      {/* {contextHolder} */}
      {children}
    </ApolloProvider>
  );
}
