import { createContext } from "react";

type CompanyStatsContext = {
  nbFiles: number;
  nbVendors: number;
  nbSubscriptions: number;
};

export const CompanyStatsContext = createContext<CompanyStatsContext>({
  nbFiles: 0,
  nbVendors: 0,
  nbSubscriptions: 0,
});
