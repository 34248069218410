import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: number; output: number; }
  date: { input: string; output: string; }
  daterange: { input: any; output: any; }
  interval: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  timestamptz: { input: any; output: any; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "Company" */
export type Company = {
  __typename?: 'Company';
  /** An array relationship */
  CompanyProducts: Array<CompanyProduct>;
  /** An array relationship */
  Files: Array<File>;
  /** An aggregate relationship */
  Files_aggregate: File_Aggregate;
  /** An array relationship */
  Orders: Array<Order>;
  /** An array relationship */
  Subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  Subscriptions_aggregate: Subscription_Aggregate;
  /** An array relationship */
  Vendors: Array<CompanyVendor>;
  /** An aggregate relationship */
  Vendors_aggregate: CompanyVendor_Aggregate;
  domain: Scalars['String']['output'];
  external_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};


/** columns and relationships of "Company" */
export type CompanyCompanyProductsArgs = {
  distinct_on?: InputMaybe<Array<CompanyProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyProduct_Order_By>>;
  where?: InputMaybe<CompanyProduct_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyFilesArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyOrdersArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanySubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanySubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyVendorsArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};

/** columns and relationships of "CompanyProduct" */
export type CompanyProduct = {
  __typename?: 'CompanyProduct';
  /** An object relationship */
  Company: Company;
  /** An array relationship */
  Pricings: Array<Pricing>;
  /** An object relationship */
  Product: Product;
  /** An object relationship */
  Vendor: Vendor;
  annual_cost?: Maybe<Scalars['bigint']['output']>;
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  product_id: Scalars['uuid']['output'];
  properties?: Maybe<Scalars['jsonb']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  vendor_id: Scalars['uuid']['output'];
};


/** columns and relationships of "CompanyProduct" */
export type CompanyProductPricingsArgs = {
  distinct_on?: InputMaybe<Array<Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pricing_Order_By>>;
  where?: InputMaybe<Pricing_Bool_Exp>;
};


/** columns and relationships of "CompanyProduct" */
export type CompanyProductPropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "CompanyProduct" */
export type CompanyProduct_Aggregate_Order_By = {
  avg?: InputMaybe<CompanyProduct_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompanyProduct_Max_Order_By>;
  min?: InputMaybe<CompanyProduct_Min_Order_By>;
  stddev?: InputMaybe<CompanyProduct_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompanyProduct_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompanyProduct_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompanyProduct_Sum_Order_By>;
  var_pop?: InputMaybe<CompanyProduct_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompanyProduct_Var_Samp_Order_By>;
  variance?: InputMaybe<CompanyProduct_Variance_Order_By>;
};

/** order by avg() on columns of table "CompanyProduct" */
export type CompanyProduct_Avg_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompanyProduct". All fields are combined with a logical 'AND'. */
export type CompanyProduct_Bool_Exp = {
  Company?: InputMaybe<Company_Bool_Exp>;
  Pricings?: InputMaybe<Pricing_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<CompanyProduct_Bool_Exp>>;
  _not?: InputMaybe<CompanyProduct_Bool_Exp>;
  _or?: InputMaybe<Array<CompanyProduct_Bool_Exp>>;
  annual_cost?: InputMaybe<Bigint_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "CompanyProduct" */
export type CompanyProduct_Max_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "CompanyProduct" */
export type CompanyProduct_Min_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "CompanyProduct". */
export type CompanyProduct_Order_By = {
  Company?: InputMaybe<Company_Order_By>;
  Pricings_aggregate?: InputMaybe<Pricing_Aggregate_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  annual_cost?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "CompanyProduct" */
export enum CompanyProduct_Select_Column {
  /** column name */
  AnnualCost = 'annual_cost',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** order by stddev() on columns of table "CompanyProduct" */
export type CompanyProduct_Stddev_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "CompanyProduct" */
export type CompanyProduct_Stddev_Pop_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "CompanyProduct" */
export type CompanyProduct_Stddev_Samp_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompanyProduct" */
export type CompanyProduct_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompanyProduct_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyProduct_Stream_Cursor_Value_Input = {
  annual_cost?: InputMaybe<Scalars['bigint']['input']>;
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by sum() on columns of table "CompanyProduct" */
export type CompanyProduct_Sum_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "CompanyProduct" */
export type CompanyProduct_Var_Pop_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "CompanyProduct" */
export type CompanyProduct_Var_Samp_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "CompanyProduct" */
export type CompanyProduct_Variance_Order_By = {
  annual_cost?: InputMaybe<Order_By>;
};

/** columns and relationships of "CompanyVendor" */
export type CompanyVendor = {
  __typename?: 'CompanyVendor';
  /** An object relationship */
  Company: Company;
  /** An object relationship */
  Vendor: Vendor;
  company_id: Scalars['uuid']['output'];
  ended_at?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  nb_insights: Scalars['bigint']['output'];
  nb_prices: Scalars['bigint']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "CompanyVendor" */
export type CompanyVendor_Aggregate = {
  __typename?: 'CompanyVendor_aggregate';
  aggregate?: Maybe<CompanyVendor_Aggregate_Fields>;
  nodes: Array<CompanyVendor>;
};

export type CompanyVendor_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompanyVendor_Aggregate_Bool_Exp_Count>;
};

export type CompanyVendor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CompanyVendor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompanyVendor" */
export type CompanyVendor_Aggregate_Fields = {
  __typename?: 'CompanyVendor_aggregate_fields';
  avg?: Maybe<CompanyVendor_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CompanyVendor_Max_Fields>;
  min?: Maybe<CompanyVendor_Min_Fields>;
  stddev?: Maybe<CompanyVendor_Stddev_Fields>;
  stddev_pop?: Maybe<CompanyVendor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompanyVendor_Stddev_Samp_Fields>;
  sum?: Maybe<CompanyVendor_Sum_Fields>;
  var_pop?: Maybe<CompanyVendor_Var_Pop_Fields>;
  var_samp?: Maybe<CompanyVendor_Var_Samp_Fields>;
  variance?: Maybe<CompanyVendor_Variance_Fields>;
};


/** aggregate fields of "CompanyVendor" */
export type CompanyVendor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "CompanyVendor" */
export type CompanyVendor_Aggregate_Order_By = {
  avg?: InputMaybe<CompanyVendor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompanyVendor_Max_Order_By>;
  min?: InputMaybe<CompanyVendor_Min_Order_By>;
  stddev?: InputMaybe<CompanyVendor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompanyVendor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompanyVendor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompanyVendor_Sum_Order_By>;
  var_pop?: InputMaybe<CompanyVendor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompanyVendor_Var_Samp_Order_By>;
  variance?: InputMaybe<CompanyVendor_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CompanyVendor_Avg_Fields = {
  __typename?: 'CompanyVendor_avg_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "CompanyVendor" */
export type CompanyVendor_Avg_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompanyVendor". All fields are combined with a logical 'AND'. */
export type CompanyVendor_Bool_Exp = {
  Company?: InputMaybe<Company_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<CompanyVendor_Bool_Exp>>;
  _not?: InputMaybe<CompanyVendor_Bool_Exp>;
  _or?: InputMaybe<Array<CompanyVendor_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  ended_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_insights?: InputMaybe<Bigint_Comparison_Exp>;
  nb_prices?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type CompanyVendor_Max_Fields = {
  __typename?: 'CompanyVendor_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  ended_at?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_insights?: Maybe<Scalars['bigint']['output']>;
  nb_prices?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "CompanyVendor" */
export type CompanyVendor_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompanyVendor_Min_Fields = {
  __typename?: 'CompanyVendor_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  ended_at?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_insights?: Maybe<Scalars['bigint']['output']>;
  nb_prices?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "CompanyVendor" */
export type CompanyVendor_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "CompanyVendor". */
export type CompanyVendor_Order_By = {
  Company?: InputMaybe<Company_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  company_id?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "CompanyVendor" */
export enum CompanyVendor_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NbInsights = 'nb_insights',
  /** column name */
  NbPrices = 'nb_prices',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type CompanyVendor_Stddev_Fields = {
  __typename?: 'CompanyVendor_stddev_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "CompanyVendor" */
export type CompanyVendor_Stddev_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompanyVendor_Stddev_Pop_Fields = {
  __typename?: 'CompanyVendor_stddev_pop_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "CompanyVendor" */
export type CompanyVendor_Stddev_Pop_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompanyVendor_Stddev_Samp_Fields = {
  __typename?: 'CompanyVendor_stddev_samp_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "CompanyVendor" */
export type CompanyVendor_Stddev_Samp_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompanyVendor" */
export type CompanyVendor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompanyVendor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyVendor_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']['input']>;
  ended_at?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_insights?: InputMaybe<Scalars['bigint']['input']>;
  nb_prices?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CompanyVendor_Sum_Fields = {
  __typename?: 'CompanyVendor_sum_fields';
  nb_insights?: Maybe<Scalars['bigint']['output']>;
  nb_prices?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "CompanyVendor" */
export type CompanyVendor_Sum_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type CompanyVendor_Var_Pop_Fields = {
  __typename?: 'CompanyVendor_var_pop_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "CompanyVendor" */
export type CompanyVendor_Var_Pop_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompanyVendor_Var_Samp_Fields = {
  __typename?: 'CompanyVendor_var_samp_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "CompanyVendor" */
export type CompanyVendor_Var_Samp_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompanyVendor_Variance_Fields = {
  __typename?: 'CompanyVendor_variance_fields';
  nb_insights?: Maybe<Scalars['Float']['output']>;
  nb_prices?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "CompanyVendor" */
export type CompanyVendor_Variance_Order_By = {
  nb_insights?: InputMaybe<Order_By>;
  nb_prices?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  CompanyProducts?: InputMaybe<CompanyProduct_Bool_Exp>;
  Files?: InputMaybe<File_Bool_Exp>;
  Files_aggregate?: InputMaybe<File_Aggregate_Bool_Exp>;
  Orders?: InputMaybe<Order_Bool_Exp>;
  Subscriptions?: InputMaybe<Subscription_Bool_Exp>;
  Subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Bool_Exp>;
  Vendors?: InputMaybe<CompanyVendor_Bool_Exp>;
  Vendors_aggregate?: InputMaybe<CompanyVendor_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  domain?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "Company". */
export type Company_Order_By = {
  CompanyProducts_aggregate?: InputMaybe<CompanyProduct_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<File_Aggregate_Order_By>;
  Orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  Subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Order_By>;
  Vendors_aggregate?: InputMaybe<CompanyVendor_Aggregate_Order_By>;
  domain?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** columns and relationships of "File" */
export type File = {
  __typename?: 'File';
  /** An object relationship */
  Company?: Maybe<Company>;
  /** An array relationship */
  Orders: Array<Order>;
  /** An object relationship */
  Vendor?: Maybe<Vendor>;
  date?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  storage_name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  uploaded_at: Scalars['timestamptz']['output'];
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "File" */
export type FileOrdersArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** aggregated selection of "File" */
export type File_Aggregate = {
  __typename?: 'File_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

export type File_Aggregate_Bool_Exp = {
  count?: InputMaybe<File_Aggregate_Bool_Exp_Count>;
};

export type File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "File" */
export type File_Aggregate_Fields = {
  __typename?: 'File_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
};


/** aggregate fields of "File" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "File" */
export type File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<File_Max_Order_By>;
  min?: InputMaybe<File_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "File". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  Company?: InputMaybe<Company_Bool_Exp>;
  Orders?: InputMaybe<Order_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  storage_name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "File" */
export enum File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'File_pkey'
}

/** input type for inserting data into table "File" */
export type File_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  storage_name?: InputMaybe<Scalars['String']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'File_max_fields';
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storage_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uploaded_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "File" */
export type File_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'File_min_fields';
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storage_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uploaded_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "File" */
export type File_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "File" */
export type File_Mutation_Response = {
  __typename?: 'File_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** on_conflict condition type for table "File" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "File". */
export type File_Order_By = {
  Company?: InputMaybe<Company_Order_By>;
  Orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "File" */
export enum File_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  StorageName = 'storage_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** placeholder for update columns of table "File" (current role has no relevant permissions) */
export enum File_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "InactiveCompanyVendor" */
export type InactiveCompanyVendor = {
  __typename?: 'InactiveCompanyVendor';
  /** An object relationship */
  Company?: Maybe<Company>;
  company_id: Scalars['uuid']['output'];
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  vendor_id: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "InactiveCompanyVendor". All fields are combined with a logical 'AND'. */
export type InactiveCompanyVendor_Bool_Exp = {
  Company?: InputMaybe<Company_Bool_Exp>;
  _and?: InputMaybe<Array<InactiveCompanyVendor_Bool_Exp>>;
  _not?: InputMaybe<InactiveCompanyVendor_Bool_Exp>;
  _or?: InputMaybe<Array<InactiveCompanyVendor_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  confirmed?: InputMaybe<Boolean_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** response of any mutation on the table "InactiveCompanyVendor" */
export type InactiveCompanyVendor_Mutation_Response = {
  __typename?: 'InactiveCompanyVendor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InactiveCompanyVendor>;
};

/** primary key columns input for table: InactiveCompanyVendor */
export type InactiveCompanyVendor_Pk_Columns_Input = {
  company_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

/** input type for updating data in table "InactiveCompanyVendor" */
export type InactiveCompanyVendor_Set_Input = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InactiveCompanyVendor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InactiveCompanyVendor_Set_Input>;
  /** filter the rows which have to be updated */
  where: InactiveCompanyVendor_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** columns and relationships of "Order" */
export type Order = {
  __typename?: 'Order';
  /** An object relationship */
  Company: Company;
  /** An object relationship */
  File: File;
  /** An array relationship */
  Prices: Array<Price>;
  /** An object relationship */
  Subscription?: Maybe<Subscription>;
  /** An object relationship */
  Vendor: Vendor;
  billed_period?: Maybe<Scalars['jsonb']['output']>;
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  type: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  vendor_id: Scalars['uuid']['output'];
};


/** columns and relationships of "Order" */
export type OrderPricesArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


/** columns and relationships of "Order" */
export type OrderBilled_PeriodArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "Order" */
export type Order_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Max_Order_By>;
  min?: InputMaybe<Order_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  Company?: InputMaybe<Company_Bool_Exp>;
  File?: InputMaybe<File_Bool_Exp>;
  Prices?: InputMaybe<Price_Bool_Exp>;
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  billed_period?: InputMaybe<Jsonb_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "Order" */
export type Order_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "Order" */
export type Order_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "Order". */
export type Order_Order_By = {
  Company?: InputMaybe<Company_Order_By>;
  File?: InputMaybe<File_Order_By>;
  Prices_aggregate?: InputMaybe<Price_Aggregate_Order_By>;
  Subscription?: InputMaybe<Subscription_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  billed_period?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "Order" */
export enum Order_Select_Column {
  /** column name */
  BilledPeriod = 'billed_period',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** columns and relationships of "Price" */
export type Price = {
  __typename?: 'Price';
  /** An object relationship */
  Order: Order;
  /** An object relationship */
  Product: Product;
  /** An object relationship */
  Segment?: Maybe<Segment>;
  id: Scalars['uuid']['output'];
  product_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "Price" */
export type Price_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Max_Order_By>;
  min?: InputMaybe<Price_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Price". All fields are combined with a logical 'AND'. */
export type Price_Bool_Exp = {
  Order?: InputMaybe<Order_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  Segment?: InputMaybe<Segment_Bool_Exp>;
  _and?: InputMaybe<Array<Price_Bool_Exp>>;
  _not?: InputMaybe<Price_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "Price" */
export type Price_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "Price" */
export type Price_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "Price". */
export type Price_Order_By = {
  Order?: InputMaybe<Order_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  Segment?: InputMaybe<Segment_Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** select columns of table "Price" */
export enum Price_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id'
}

/** Streaming cursor of the table "Price" */
export type Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "Pricing" */
export type Pricing = {
  __typename?: 'Pricing';
  /** An object relationship */
  CompanyProduct?: Maybe<CompanyProduct>;
  /** An object relationship */
  Segment?: Maybe<PricingSegment>;
  company_product_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  normalized_amount: Scalars['bigint']['output'];
  normalized_amount_decimal: Scalars['String']['output'];
  period: Scalars['daterange']['output'];
  period_start?: Maybe<Scalars['date']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  segment_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

export type PricingSample = {
  __typename?: 'PricingSample';
  currency: Scalars['String']['output'];
  normalized_amount: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  start_date: Scalars['timestamptz']['output'];
};

export type PricingSamples = {
  __typename?: 'PricingSamples';
  pricings: Array<PricingSample>;
};

/** columns and relationships of "PricingSegment" */
export type PricingSegment = {
  __typename?: 'PricingSegment';
  /** An array relationship */
  SegmentCondition: Array<SegmentCondition>;
  /** An array relationship */
  SegmentStats: Array<SegmentStat>;
  id?: Maybe<Scalars['uuid']['output']>;
  pricing_id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "PricingSegment" */
export type PricingSegmentSegmentConditionArgs = {
  distinct_on?: InputMaybe<Array<SegmentCondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SegmentCondition_Order_By>>;
  where?: InputMaybe<SegmentCondition_Bool_Exp>;
};


/** columns and relationships of "PricingSegment" */
export type PricingSegmentSegmentStatsArgs = {
  distinct_on?: InputMaybe<Array<SegmentStat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SegmentStat_Order_By>>;
  where?: InputMaybe<SegmentStat_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "PricingSegment". All fields are combined with a logical 'AND'. */
export type PricingSegment_Bool_Exp = {
  SegmentCondition?: InputMaybe<SegmentCondition_Bool_Exp>;
  SegmentStats?: InputMaybe<SegmentStat_Bool_Exp>;
  _and?: InputMaybe<Array<PricingSegment_Bool_Exp>>;
  _not?: InputMaybe<PricingSegment_Bool_Exp>;
  _or?: InputMaybe<Array<PricingSegment_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pricing_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "PricingSegment". */
export type PricingSegment_Order_By = {
  SegmentCondition_aggregate?: InputMaybe<SegmentCondition_Aggregate_Order_By>;
  SegmentStats_aggregate?: InputMaybe<SegmentStat_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  pricing_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "Pricing" */
export type Pricing_Aggregate_Order_By = {
  avg?: InputMaybe<Pricing_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pricing_Max_Order_By>;
  min?: InputMaybe<Pricing_Min_Order_By>;
  stddev?: InputMaybe<Pricing_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pricing_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pricing_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pricing_Sum_Order_By>;
  var_pop?: InputMaybe<Pricing_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pricing_Var_Samp_Order_By>;
  variance?: InputMaybe<Pricing_Variance_Order_By>;
};

/** order by avg() on columns of table "Pricing" */
export type Pricing_Avg_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Pricing". All fields are combined with a logical 'AND'. */
export type Pricing_Bool_Exp = {
  CompanyProduct?: InputMaybe<CompanyProduct_Bool_Exp>;
  Segment?: InputMaybe<PricingSegment_Bool_Exp>;
  _and?: InputMaybe<Array<Pricing_Bool_Exp>>;
  _not?: InputMaybe<Pricing_Bool_Exp>;
  _or?: InputMaybe<Array<Pricing_Bool_Exp>>;
  company_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  normalized_amount?: InputMaybe<Bigint_Comparison_Exp>;
  normalized_amount_decimal?: InputMaybe<String_Comparison_Exp>;
  period?: InputMaybe<Daterange_Comparison_Exp>;
  period_start?: InputMaybe<Date_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  segment_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "Pricing" */
export type Pricing_Max_Order_By = {
  company_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  normalized_amount?: InputMaybe<Order_By>;
  normalized_amount_decimal?: InputMaybe<Order_By>;
  period_start?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "Pricing" */
export type Pricing_Min_Order_By = {
  company_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  normalized_amount?: InputMaybe<Order_By>;
  normalized_amount_decimal?: InputMaybe<Order_By>;
  period_start?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "Pricing". */
export type Pricing_Order_By = {
  CompanyProduct?: InputMaybe<CompanyProduct_Order_By>;
  Segment?: InputMaybe<PricingSegment_Order_By>;
  company_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  normalized_amount?: InputMaybe<Order_By>;
  normalized_amount_decimal?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  period_start?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "Pricing" */
export enum Pricing_Select_Column {
  /** column name */
  CompanyProductId = 'company_product_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  NormalizedAmount = 'normalized_amount',
  /** column name */
  NormalizedAmountDecimal = 'normalized_amount_decimal',
  /** column name */
  Period = 'period',
  /** column name */
  PeriodStart = 'period_start',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "Pricing" */
export type Pricing_Stddev_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "Pricing" */
export type Pricing_Stddev_Pop_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "Pricing" */
export type Pricing_Stddev_Samp_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Pricing" */
export type Pricing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pricing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pricing_Stream_Cursor_Value_Input = {
  company_product_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  normalized_amount?: InputMaybe<Scalars['bigint']['input']>;
  normalized_amount_decimal?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['daterange']['input']>;
  period_start?: InputMaybe<Scalars['date']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  segment_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "Pricing" */
export type Pricing_Sum_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "Pricing" */
export type Pricing_Var_Pop_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "Pricing" */
export type Pricing_Var_Samp_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "Pricing" */
export type Pricing_Variance_Order_By = {
  normalized_amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "Product" */
export type Product = {
  __typename?: 'Product';
  /** An object relationship */
  ProductUnit?: Maybe<ProductUnit>;
  /** An array relationship */
  Segments: Array<Segment>;
  /** An object relationship */
  Vendor: Vendor;
  bench_type: Scalars['String']['output'];
  category_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  order_of_magnitude: Scalars['Int']['output'];
  pricing_type: Scalars['String']['output'];
  properties?: Maybe<Scalars['jsonb']['output']>;
  segmentation?: Maybe<Scalars['jsonb']['output']>;
  type: Scalars['String']['output'];
  unit_id?: Maybe<Scalars['uuid']['output']>;
  unit_label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  vendor_id: Scalars['uuid']['output'];
};


/** columns and relationships of "Product" */
export type ProductSegmentsArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductPropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "Product" */
export type ProductSegmentationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ProductUnit" */
export type ProductUnit = {
  __typename?: 'ProductUnit';
  display_name: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "ProductUnit". All fields are combined with a logical 'AND'. */
export type ProductUnit_Bool_Exp = {
  _and?: InputMaybe<Array<ProductUnit_Bool_Exp>>;
  _not?: InputMaybe<ProductUnit_Bool_Exp>;
  _or?: InputMaybe<Array<ProductUnit_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "ProductUnit". */
export type ProductUnit_Order_By = {
  display_name?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "Product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Max_Order_By>;
  min?: InputMaybe<Product_Min_Order_By>;
  stddev?: InputMaybe<Product_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Variance_Order_By>;
};

/** order by avg() on columns of table "Product" */
export type Product_Avg_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  ProductUnit?: InputMaybe<ProductUnit_Bool_Exp>;
  Segments?: InputMaybe<Segment_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  bench_type?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_of_magnitude?: InputMaybe<Int_Comparison_Exp>;
  pricing_type?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  segmentation?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  unit_label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "Product" */
export type Product_Max_Order_By = {
  bench_type?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_of_magnitude?: InputMaybe<Order_By>;
  pricing_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  unit_label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "Product" */
export type Product_Min_Order_By = {
  bench_type?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_of_magnitude?: InputMaybe<Order_By>;
  pricing_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  unit_label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "Product". */
export type Product_Order_By = {
  ProductUnit?: InputMaybe<ProductUnit_Order_By>;
  Segments_aggregate?: InputMaybe<Segment_Aggregate_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  bench_type?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_of_magnitude?: InputMaybe<Order_By>;
  pricing_type?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  segmentation?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  unit_label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "Product" */
export enum Product_Select_Column {
  /** column name */
  BenchType = 'bench_type',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderOfMagnitude = 'order_of_magnitude',
  /** column name */
  PricingType = 'pricing_type',
  /** column name */
  Properties = 'properties',
  /** column name */
  Segmentation = 'segmentation',
  /** column name */
  Type = 'type',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitLabel = 'unit_label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** order by stddev() on columns of table "Product" */
export type Product_Stddev_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "Product" */
export type Product_Stddev_Pop_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "Product" */
export type Product_Stddev_Samp_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "Product" */
export type Product_Sum_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "Product" */
export type Product_Var_Pop_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "Product" */
export type Product_Var_Samp_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "Product" */
export type Product_Variance_Order_By = {
  order_of_magnitude?: InputMaybe<Order_By>;
};

export type ReadUrlOutput = {
  __typename?: 'ReadUrlOutput';
  url: Scalars['String']['output'];
};

/** columns and relationships of "Segment" */
export type Segment = {
  __typename?: 'Segment';
  /** An array relationship */
  Prices: Array<Price>;
  /** An array relationship */
  Pricings: Array<Pricing>;
  /** An object relationship */
  Product: Product;
  /** An array relationship */
  SegmentConditions: Array<SegmentCondition>;
  /** An array relationship */
  SegmentStats: Array<SegmentStat>;
  id: Scalars['uuid']['output'];
  product_id: Scalars['uuid']['output'];
};


/** columns and relationships of "Segment" */
export type SegmentPricesArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


/** columns and relationships of "Segment" */
export type SegmentPricingsArgs = {
  distinct_on?: InputMaybe<Array<Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pricing_Order_By>>;
  where?: InputMaybe<Pricing_Bool_Exp>;
};


/** columns and relationships of "Segment" */
export type SegmentSegmentConditionsArgs = {
  distinct_on?: InputMaybe<Array<SegmentCondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SegmentCondition_Order_By>>;
  where?: InputMaybe<SegmentCondition_Bool_Exp>;
};


/** columns and relationships of "Segment" */
export type SegmentSegmentStatsArgs = {
  distinct_on?: InputMaybe<Array<SegmentStat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SegmentStat_Order_By>>;
  where?: InputMaybe<SegmentStat_Bool_Exp>;
};

/** columns and relationships of "SegmentCondition" */
export type SegmentCondition = {
  __typename?: 'SegmentCondition';
  /** An object relationship */
  Segment: Segment;
  id: Scalars['uuid']['output'];
  operator: Scalars['String']['output'];
  property: Scalars['String']['output'];
  property_type: Scalars['String']['output'];
  segment_id: Scalars['uuid']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "SegmentCondition" */
export type SegmentConditionValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "SegmentCondition" */
export type SegmentCondition_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SegmentCondition_Max_Order_By>;
  min?: InputMaybe<SegmentCondition_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "SegmentCondition". All fields are combined with a logical 'AND'. */
export type SegmentCondition_Bool_Exp = {
  Segment?: InputMaybe<Segment_Bool_Exp>;
  _and?: InputMaybe<Array<SegmentCondition_Bool_Exp>>;
  _not?: InputMaybe<SegmentCondition_Bool_Exp>;
  _or?: InputMaybe<Array<SegmentCondition_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  operator?: InputMaybe<String_Comparison_Exp>;
  property?: InputMaybe<String_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
  segment_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** order by max() on columns of table "SegmentCondition" */
export type SegmentCondition_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  property?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "SegmentCondition" */
export type SegmentCondition_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  property?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "SegmentCondition". */
export type SegmentCondition_Order_By = {
  Segment?: InputMaybe<Segment_Order_By>;
  id?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  property?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "SegmentCondition" */
export enum SegmentCondition_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Operator = 'operator',
  /** column name */
  Property = 'property',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "SegmentStat" */
export type SegmentStat = {
  __typename?: 'SegmentStat';
  /** An object relationship */
  Segment: Segment;
  avg: Scalars['bigint']['output'];
  correlations?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  max: Scalars['bigint']['output'];
  min: Scalars['bigint']['output'];
  p25: Scalars['bigint']['output'];
  p50: Scalars['bigint']['output'];
  p75: Scalars['bigint']['output'];
  published: Scalars['Boolean']['output'];
  segment_id: Scalars['uuid']['output'];
  std: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "SegmentStat" */
export type SegmentStatCorrelationsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "SegmentStat" */
export type SegmentStat_Aggregate_Order_By = {
  avg?: InputMaybe<SegmentStat_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SegmentStat_Max_Order_By>;
  min?: InputMaybe<SegmentStat_Min_Order_By>;
  stddev?: InputMaybe<SegmentStat_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SegmentStat_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SegmentStat_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SegmentStat_Sum_Order_By>;
  var_pop?: InputMaybe<SegmentStat_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SegmentStat_Var_Samp_Order_By>;
  variance?: InputMaybe<SegmentStat_Variance_Order_By>;
};

/** order by avg() on columns of table "SegmentStat" */
export type SegmentStat_Avg_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SegmentStat". All fields are combined with a logical 'AND'. */
export type SegmentStat_Bool_Exp = {
  Segment?: InputMaybe<Segment_Bool_Exp>;
  _and?: InputMaybe<Array<SegmentStat_Bool_Exp>>;
  _not?: InputMaybe<SegmentStat_Bool_Exp>;
  _or?: InputMaybe<Array<SegmentStat_Bool_Exp>>;
  avg?: InputMaybe<Bigint_Comparison_Exp>;
  correlations?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  max?: InputMaybe<Bigint_Comparison_Exp>;
  min?: InputMaybe<Bigint_Comparison_Exp>;
  p25?: InputMaybe<Bigint_Comparison_Exp>;
  p50?: InputMaybe<Bigint_Comparison_Exp>;
  p75?: InputMaybe<Bigint_Comparison_Exp>;
  published?: InputMaybe<Boolean_Comparison_Exp>;
  segment_id?: InputMaybe<Uuid_Comparison_Exp>;
  std?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "SegmentStat" */
export type SegmentStat_Max_Order_By = {
  avg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "SegmentStat" */
export type SegmentStat_Min_Order_By = {
  avg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "SegmentStat". */
export type SegmentStat_Order_By = {
  Segment?: InputMaybe<Segment_Order_By>;
  avg?: InputMaybe<Order_By>;
  correlations?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  published?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "SegmentStat" */
export enum SegmentStat_Select_Column {
  /** column name */
  Avg = 'avg',
  /** column name */
  Correlations = 'correlations',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  P25 = 'p25',
  /** column name */
  P50 = 'p50',
  /** column name */
  P75 = 'p75',
  /** column name */
  Published = 'published',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  Std = 'std',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "SegmentStat" */
export type SegmentStat_Stddev_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "SegmentStat" */
export type SegmentStat_Stddev_Pop_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "SegmentStat" */
export type SegmentStat_Stddev_Samp_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "SegmentStat" */
export type SegmentStat_Sum_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "SegmentStat" */
export type SegmentStat_Var_Pop_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "SegmentStat" */
export type SegmentStat_Var_Samp_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "SegmentStat" */
export type SegmentStat_Variance_Order_By = {
  avg?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  p25?: InputMaybe<Order_By>;
  p50?: InputMaybe<Order_By>;
  p75?: InputMaybe<Order_By>;
  std?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "Segment" */
export type Segment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Segment_Max_Order_By>;
  min?: InputMaybe<Segment_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Segment". All fields are combined with a logical 'AND'. */
export type Segment_Bool_Exp = {
  Prices?: InputMaybe<Price_Bool_Exp>;
  Pricings?: InputMaybe<Pricing_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  SegmentConditions?: InputMaybe<SegmentCondition_Bool_Exp>;
  SegmentStats?: InputMaybe<SegmentStat_Bool_Exp>;
  _and?: InputMaybe<Array<Segment_Bool_Exp>>;
  _not?: InputMaybe<Segment_Bool_Exp>;
  _or?: InputMaybe<Array<Segment_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "Segment" */
export type Segment_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "Segment" */
export type Segment_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "Segment". */
export type Segment_Order_By = {
  Prices_aggregate?: InputMaybe<Price_Aggregate_Order_By>;
  Pricings_aggregate?: InputMaybe<Pricing_Aggregate_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  SegmentConditions_aggregate?: InputMaybe<SegmentCondition_Aggregate_Order_By>;
  SegmentStats_aggregate?: InputMaybe<SegmentStat_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** select columns of table "Segment" */
export enum Segment_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id'
}

export type SlackConnectionStatus = {
  __typename?: 'SlackConnectionStatus';
  connected: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "Subscription" */
export type Subscription = {
  __typename?: 'Subscription';
  /** An object relationship */
  Amount?: Maybe<SubscriptionAmount>;
  /** An object relationship */
  Company: Company;
  /** An object relationship */
  CompanyVendor?: Maybe<CompanyVendor>;
  /** An array relationship */
  Orders: Array<Order>;
  /** An object relationship */
  Vendor: Vendor;
  auto_renewal?: Maybe<Scalars['Boolean']['output']>;
  company_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  ends_at?: Maybe<Scalars['date']['output']>;
  extra_contractual_file_id?: Maybe<Scalars['uuid']['output']>;
  has_extra_contractual_info?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  implicit_dates: Scalars['Boolean']['output'];
  implicit_term: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notice_period?: Maybe<Scalars['interval']['output']>;
  recurring: Scalars['jsonb']['output'];
  starts_at?: Maybe<Scalars['date']['output']>;
  term?: Maybe<Scalars['jsonb']['output']>;
  trial_period?: Maybe<Scalars['jsonb']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  vendor_id: Scalars['uuid']['output'];
};


/** columns and relationships of "Subscription" */
export type SubscriptionOrdersArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionRecurringArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionTermArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionTrial_PeriodArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "SubscriptionAmount" */
export type SubscriptionAmount = {
  __typename?: 'SubscriptionAmount';
  /** An object relationship */
  Subscription?: Maybe<Subscription>;
  annual_amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  id?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to filter rows from the table "SubscriptionAmount". All fields are combined with a logical 'AND'. */
export type SubscriptionAmount_Bool_Exp = {
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  _and?: InputMaybe<Array<SubscriptionAmount_Bool_Exp>>;
  _not?: InputMaybe<SubscriptionAmount_Bool_Exp>;
  _or?: InputMaybe<Array<SubscriptionAmount_Bool_Exp>>;
  annual_amount?: InputMaybe<Int_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "SubscriptionAmount". */
export type SubscriptionAmount_Order_By = {
  Subscription?: InputMaybe<Subscription_Order_By>;
  annual_amount?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Subscription" */
export type Subscription_Aggregate = {
  __typename?: 'Subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

export type Subscription_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Subscription_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Subscription_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Subscription_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Aggregate_Bool_Exp_Bool_And = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'Subscription_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
};


/** aggregate fields of "Subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Subscription" */
export type Subscription_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Max_Order_By>;
  min?: InputMaybe<Subscription_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  Amount?: InputMaybe<SubscriptionAmount_Bool_Exp>;
  Company?: InputMaybe<Company_Bool_Exp>;
  CompanyVendor?: InputMaybe<CompanyVendor_Bool_Exp>;
  Orders?: InputMaybe<Order_Bool_Exp>;
  Vendor?: InputMaybe<Vendor_Bool_Exp>;
  _and?: InputMaybe<Array<Subscription_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Bool_Exp>>;
  auto_renewal?: InputMaybe<Boolean_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ends_at?: InputMaybe<Date_Comparison_Exp>;
  extra_contractual_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  has_extra_contractual_info?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  implicit_dates?: InputMaybe<Boolean_Comparison_Exp>;
  implicit_term?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notice_period?: InputMaybe<Interval_Comparison_Exp>;
  recurring?: InputMaybe<Jsonb_Comparison_Exp>;
  starts_at?: InputMaybe<Date_Comparison_Exp>;
  term?: InputMaybe<Jsonb_Comparison_Exp>;
  trial_period?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'Subscription_max_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  ends_at?: Maybe<Scalars['date']['output']>;
  extra_contractual_file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  starts_at?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "Subscription" */
export type Subscription_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ends_at?: InputMaybe<Order_By>;
  extra_contractual_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  starts_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'Subscription_min_fields';
  company_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  ends_at?: Maybe<Scalars['date']['output']>;
  extra_contractual_file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  starts_at?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "Subscription" */
export type Subscription_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ends_at?: InputMaybe<Order_By>;
  extra_contractual_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  starts_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'Subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** Ordering options when selecting data from "Subscription". */
export type Subscription_Order_By = {
  Amount?: InputMaybe<SubscriptionAmount_Order_By>;
  Company?: InputMaybe<Company_Order_By>;
  CompanyVendor?: InputMaybe<CompanyVendor_Order_By>;
  Orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  Vendor?: InputMaybe<Vendor_Order_By>;
  auto_renewal?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ends_at?: InputMaybe<Order_By>;
  extra_contractual_file_id?: InputMaybe<Order_By>;
  has_extra_contractual_info?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  implicit_dates?: InputMaybe<Order_By>;
  implicit_term?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notice_period?: InputMaybe<Order_By>;
  recurring?: InputMaybe<Order_By>;
  starts_at?: InputMaybe<Order_By>;
  term?: InputMaybe<Order_By>;
  trial_period?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "Subscription" */
export enum Subscription_Select_Column {
  /** column name */
  AutoRenewal = 'auto_renewal',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraContractualFileId = 'extra_contractual_file_id',
  /** column name */
  HasExtraContractualInfo = 'has_extra_contractual_info',
  /** column name */
  Id = 'id',
  /** column name */
  ImplicitDates = 'implicit_dates',
  /** column name */
  ImplicitTerm = 'implicit_term',
  /** column name */
  Name = 'name',
  /** column name */
  NoticePeriod = 'notice_period',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Term = 'term',
  /** column name */
  TrialPeriod = 'trial_period',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** select "Subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AutoRenewal = 'auto_renewal',
  /** column name */
  HasExtraContractualInfo = 'has_extra_contractual_info',
  /** column name */
  ImplicitDates = 'implicit_dates',
  /** column name */
  ImplicitTerm = 'implicit_term'
}

/** select "Subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AutoRenewal = 'auto_renewal',
  /** column name */
  HasExtraContractualInfo = 'has_extra_contractual_info',
  /** column name */
  ImplicitDates = 'implicit_dates',
  /** column name */
  ImplicitTerm = 'implicit_term'
}

/** input type for updating data in table "Subscription" */
export type Subscription_Set_Input = {
  extra_contractual_file_id?: InputMaybe<Scalars['uuid']['input']>;
  has_extra_contractual_info?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Bool_Exp;
};

export type UploadUrlOutput = {
  __typename?: 'UploadUrlOutput';
  url: Scalars['String']['output'];
};

/** columns and relationships of "Vendor" */
export type Vendor = {
  __typename?: 'Vendor';
  /** An array relationship */
  Files: Array<File>;
  /** An aggregate relationship */
  Files_aggregate: File_Aggregate;
  /** An array relationship */
  Products: Array<Product>;
  category_id?: Maybe<Scalars['uuid']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


/** columns and relationships of "Vendor" */
export type VendorFilesArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** columns and relationships of "Vendor" */
export type VendorFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


/** columns and relationships of "Vendor" */
export type VendorProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "Vendor". All fields are combined with a logical 'AND'. */
export type Vendor_Bool_Exp = {
  Files?: InputMaybe<File_Bool_Exp>;
  Files_aggregate?: InputMaybe<File_Aggregate_Bool_Exp>;
  Products?: InputMaybe<Product_Bool_Exp>;
  _and?: InputMaybe<Array<Vendor_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Bool_Exp>>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "Vendor". */
export type Vendor_Order_By = {
  Files_aggregate?: InputMaybe<File_Aggregate_Order_By>;
  Products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  category_id?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** Boolean expression to compare columns of type "daterange". All fields are combined with logical 'AND'. */
export type Daterange_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['daterange']['input']>;
  _gt?: InputMaybe<Scalars['daterange']['input']>;
  _gte?: InputMaybe<Scalars['daterange']['input']>;
  _in?: InputMaybe<Array<Scalars['daterange']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['daterange']['input']>;
  _lte?: InputMaybe<Scalars['daterange']['input']>;
  _neq?: InputMaybe<Scalars['daterange']['input']>;
  _nin?: InputMaybe<Array<Scalars['daterange']['input']>>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** createCompanyUploadUrl */
  createCompanyUploadUrl: UploadUrlOutput;
  /** Retrieve a url to read a file */
  createReadUrl?: Maybe<ReadUrlOutput>;
  /** delete data from the table: "InactiveCompanyVendor" */
  delete_InactiveCompanyVendor?: Maybe<InactiveCompanyVendor_Mutation_Response>;
  /** delete single row from the table: "InactiveCompanyVendor" */
  delete_InactiveCompanyVendor_by_pk?: Maybe<InactiveCompanyVendor>;
  /** insert data into the table: "File" */
  insert_File?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "File" */
  insert_File_one?: Maybe<File>;
  resetSlackConnection?: Maybe<SlackConnectionStatus>;
  setSlackConnection?: Maybe<SlackConnectionStatus>;
  /** update data of the table: "InactiveCompanyVendor" */
  update_InactiveCompanyVendor?: Maybe<InactiveCompanyVendor_Mutation_Response>;
  /** update single row of the table: "InactiveCompanyVendor" */
  update_InactiveCompanyVendor_by_pk?: Maybe<InactiveCompanyVendor>;
  /** update multiples rows of table: "InactiveCompanyVendor" */
  update_InactiveCompanyVendor_many?: Maybe<Array<Maybe<InactiveCompanyVendor_Mutation_Response>>>;
  /** update data of the table: "Subscription" */
  update_Subscription?: Maybe<Subscription_Mutation_Response>;
  /** update single row of the table: "Subscription" */
  update_Subscription_by_pk?: Maybe<Subscription>;
  /** update multiples rows of table: "Subscription" */
  update_Subscription_many?: Maybe<Array<Maybe<Subscription_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCreateCompanyUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateReadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InactiveCompanyVendorArgs = {
  where: InactiveCompanyVendor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InactiveCompanyVendor_By_PkArgs = {
  company_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootSetSlackConnectionArgs = {
  code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_InactiveCompanyVendorArgs = {
  _set?: InputMaybe<InactiveCompanyVendor_Set_Input>;
  where: InactiveCompanyVendor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InactiveCompanyVendor_By_PkArgs = {
  _set?: InputMaybe<InactiveCompanyVendor_Set_Input>;
  pk_columns: InactiveCompanyVendor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InactiveCompanyVendor_ManyArgs = {
  updates: Array<InactiveCompanyVendor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_ManyArgs = {
  updates: Array<Subscription_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "CompanyProduct" */
  CompanyProduct: Array<CompanyProduct>;
  /** fetch data from the table: "CompanyProduct" using primary key columns */
  CompanyProduct_by_pk?: Maybe<CompanyProduct>;
  /** fetch data from the table: "CompanyVendor" */
  CompanyVendor: Array<CompanyVendor>;
  /** fetch aggregated fields from the table: "CompanyVendor" */
  CompanyVendor_aggregate: CompanyVendor_Aggregate;
  /** fetch data from the table: "File" */
  File: Array<File>;
  /** fetch aggregated fields from the table: "File" */
  File_aggregate: File_Aggregate;
  /** fetch data from the table: "Price" */
  Price: Array<Price>;
  /** fetch data from the table: "Price" using primary key columns */
  Price_by_pk?: Maybe<Price>;
  /** fetch data from the table: "Pricing" */
  Pricing: Array<Pricing>;
  /** fetch data from the table: "Pricing" using primary key columns */
  Pricing_by_pk?: Maybe<Pricing>;
  /** Retrieve the slack connection status */
  SlackConnectionStatus?: Maybe<SlackConnectionStatus>;
  /** fetch data from the table: "Subscription" */
  Subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "Subscription" */
  Subscription_aggregate: Subscription_Aggregate;
  getPricingSamples?: Maybe<PricingSamples>;
};


export type Query_RootCompanyProductArgs = {
  distinct_on?: InputMaybe<Array<CompanyProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyProduct_Order_By>>;
  where?: InputMaybe<CompanyProduct_Bool_Exp>;
};


export type Query_RootCompanyProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCompanyVendorArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


export type Query_RootCompanyVendor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootPriceArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type Query_RootPrice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPricingArgs = {
  distinct_on?: InputMaybe<Array<Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pricing_Order_By>>;
  where?: InputMaybe<Pricing_Bool_Exp>;
};


export type Query_RootPricing_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootGetPricingSamplesArgs = {
  company_product_id: Scalars['uuid']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "CompanyProduct" */
  CompanyProduct: Array<CompanyProduct>;
  /** fetch data from the table: "CompanyProduct" using primary key columns */
  CompanyProduct_by_pk?: Maybe<CompanyProduct>;
  /** fetch data from the table in a streaming manner: "CompanyProduct" */
  CompanyProduct_stream: Array<CompanyProduct>;
  /** fetch data from the table: "CompanyVendor" */
  CompanyVendor: Array<CompanyVendor>;
  /** fetch aggregated fields from the table: "CompanyVendor" */
  CompanyVendor_aggregate: CompanyVendor_Aggregate;
  /** fetch data from the table in a streaming manner: "CompanyVendor" */
  CompanyVendor_stream: Array<CompanyVendor>;
  /** fetch data from the table: "Price" */
  Price: Array<Price>;
  /** fetch data from the table: "Price" using primary key columns */
  Price_by_pk?: Maybe<Price>;
  /** fetch data from the table in a streaming manner: "Price" */
  Price_stream: Array<Price>;
  /** fetch data from the table: "Pricing" */
  Pricing: Array<Pricing>;
  /** fetch data from the table: "Pricing" using primary key columns */
  Pricing_by_pk?: Maybe<Pricing>;
  /** fetch data from the table in a streaming manner: "Pricing" */
  Pricing_stream: Array<Pricing>;
};


export type Subscription_RootCompanyProductArgs = {
  distinct_on?: InputMaybe<Array<CompanyProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyProduct_Order_By>>;
  where?: InputMaybe<CompanyProduct_Bool_Exp>;
};


export type Subscription_RootCompanyProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCompanyProduct_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompanyProduct_Stream_Cursor_Input>>;
  where?: InputMaybe<CompanyProduct_Bool_Exp>;
};


export type Subscription_RootCompanyVendorArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


export type Subscription_RootCompanyVendor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyVendor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CompanyVendor_Order_By>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


export type Subscription_RootCompanyVendor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompanyVendor_Stream_Cursor_Input>>;
  where?: InputMaybe<CompanyVendor_Bool_Exp>;
};


export type Subscription_RootPriceArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type Subscription_RootPrice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPrice_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Price_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type Subscription_RootPricingArgs = {
  distinct_on?: InputMaybe<Array<Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pricing_Order_By>>;
  where?: InputMaybe<Pricing_Bool_Exp>;
};


export type Subscription_RootPricing_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPricing_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Pricing_Stream_Cursor_Input>>;
  where?: InputMaybe<Pricing_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type ArchiveVendorMutationVariables = Exact<{
  vendorId: Scalars['uuid']['input'];
}>;


export type ArchiveVendorMutation = { __typename?: 'mutation_root', update_InactiveCompanyVendor?: { __typename?: 'InactiveCompanyVendor_mutation_response', affected_rows: number } | null };

export type CreateDownloadUrlMutationVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type CreateDownloadUrlMutation = { __typename?: 'mutation_root', createReadUrl?: { __typename?: 'ReadUrlOutput', url: string } | null };

export type CreateFileMutationVariables = Exact<{
  name: Scalars['String']['input'];
  storageName: Scalars['String']['input'];
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type CreateFileMutation = { __typename?: 'mutation_root', insert_File_one?: { __typename?: 'File', id: string } | null };

export type CreateUploadUrlMutationVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type CreateUploadUrlMutation = { __typename?: 'mutation_root', createCompanyUploadUrl: { __typename?: 'UploadUrlOutput', url: string } };

export type ReactivateVendorMutationVariables = Exact<{
  vendorId: Scalars['uuid']['input'];
}>;


export type ReactivateVendorMutation = { __typename?: 'mutation_root', update_InactiveCompanyVendor?: { __typename?: 'InactiveCompanyVendor_mutation_response', affected_rows: number } | null };

export type ResetSlackConnectionMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetSlackConnectionMutation = { __typename?: 'mutation_root', resetSlackConnection?: { __typename?: 'SlackConnectionStatus', connected: boolean, error?: string | null } | null };

export type SetSlackConnectionMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type SetSlackConnectionMutation = { __typename?: 'mutation_root', setSlackConnection?: { __typename?: 'SlackConnectionStatus', connected: boolean, error?: string | null } | null };

export type SetSubscriptionExtraInfoMutationVariables = Exact<{
  subscriptionId: Scalars['uuid']['input'];
  hasExtraInfo: Scalars['Boolean']['input'];
  fileId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type SetSubscriptionExtraInfoMutation = { __typename?: 'mutation_root', update_Subscription_by_pk?: { __typename?: 'Subscription', id: string } | null };

export type GetCompanyFilesQueryVariables = Exact<{
  where?: InputMaybe<File_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCompanyFilesQuery = { __typename?: 'query_root', File: Array<{ __typename?: 'File', name: string, storage_name: string, type?: string | null, date?: string | null, status: string, Vendor?: { __typename?: 'Vendor', id: string, name: string, logo?: string | null } | null }>, File_aggregate: { __typename?: 'File_aggregate', aggregate?: { __typename?: 'File_aggregate_fields', count: number } | null } };

export type CompanyFileFragment = { __typename?: 'File', name: string, storage_name: string, type?: string | null, date?: string | null, status: string, Vendor?: { __typename?: 'Vendor', id: string, name: string, logo?: string | null } | null };

export type GetCompanyProductQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetCompanyProductQuery = { __typename?: 'query_root', CompanyProduct_by_pk?: { __typename?: 'CompanyProduct', id: string, properties?: any | null, Vendor: { __typename?: 'Vendor', id: string, name: string, logo?: string | null }, Product: { __typename?: 'Product', id: string, name: string, order_of_magnitude: number, pricing_type: string, properties?: any | null, ProductUnit?: { __typename?: 'ProductUnit', display_name: string } | null }, Pricings: Array<{ __typename?: 'Pricing', id: string, quantity?: number | null, currency: string, normalized_amount: number, normalized_amount_decimal: string, start_date?: string | null, Segment?: { __typename?: 'PricingSegment', SegmentStats: Array<{ __typename?: 'SegmentStat', min: number, max: number, avg: number, std: number, p25: number, p50: number, p75: number, correlations?: any | null }>, SegmentCondition: Array<{ __typename?: 'SegmentCondition', id: string, property_type: string, property: string, operator: string, value: any }> } | null }> } | null, getPricingSamples?: { __typename?: 'PricingSamples', pricings: Array<{ __typename?: 'PricingSample', normalized_amount: number, currency: string, quantity: number, start_date: any }> } | null };

export type GetCompanyVendorQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
  external_id: Scalars['String']['input'];
}>;


export type GetCompanyVendorQuery = { __typename?: 'query_root', CompanyVendor: Array<{ __typename?: 'CompanyVendor', id: string, name: string, nb_insights: number, nb_prices: number, Vendor: { __typename?: 'Vendor', logo?: string | null } }> };

export type GetCompanyVendorsToConfirmQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyVendorsToConfirmQuery = { __typename?: 'query_root', CompanyVendor: Array<{ __typename?: 'CompanyVendor', ended_at?: string | null, id: string, name: string, nb_insights: number, nb_prices: number, Vendor: { __typename?: 'Vendor', logo?: string | null } }> };

export type VendorFragment = { __typename?: 'CompanyVendor', id: string, name: string, nb_insights: number, nb_prices: number, Vendor: { __typename?: 'Vendor', logo?: string | null } };

export type GetCompanyVendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyVendorsQuery = { __typename?: 'query_root', CompanyVendor: Array<{ __typename?: 'CompanyVendor', id: string, name: string, nb_insights: number, nb_prices: number, Vendor: { __typename?: 'Vendor', logo?: string | null } }> };

export type GetCompanyStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyStatsQuery = { __typename?: 'query_root', vendors: { __typename?: 'CompanyVendor_aggregate', aggregate?: { __typename?: 'CompanyVendor_aggregate_fields', count: number } | null }, files: { __typename?: 'File_aggregate', aggregate?: { __typename?: 'File_aggregate_fields', count: number } | null }, subscriptions: { __typename?: 'Subscription_aggregate', aggregate?: { __typename?: 'Subscription_aggregate_fields', count: number } | null } };

export type GetSlackConnectionStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSlackConnectionStatusQuery = { __typename?: 'query_root', SlackConnectionStatus?: { __typename?: 'SlackConnectionStatus', connected: boolean } | null };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'query_root', Subscription: Array<{ __typename?: 'Subscription', id: string, name?: string | null, starts_at?: string | null, ends_at?: string | null, recurring: any, auto_renewal?: boolean | null, notice_period?: any | null, has_extra_contractual_info?: boolean | null, CompanyVendor?: { __typename?: 'CompanyVendor', id: string, name: string, nb_insights: number, nb_prices: number, Vendor: { __typename?: 'Vendor', logo?: string | null } } | null, Orders: Array<{ __typename?: 'Order', billed_period?: any | null }>, Amount?: { __typename?: 'SubscriptionAmount', annual_amount: number, currency: string } | null }> };

export type FileFragment = { __typename?: 'File', id: string, name: string, status: string, storage_name: string, type?: string | null, date?: string | null };

export type GetVendorFilesQueryVariables = Exact<{
  vendorId: Scalars['uuid']['input'];
}>;


export type GetVendorFilesQuery = { __typename?: 'query_root', File: Array<{ __typename?: 'File', id: string, name: string, status: string, storage_name: string, type?: string | null, date?: string | null }> };

export type SegmentStatFragment = { __typename?: 'SegmentStat', min: number, max: number, avg: number, std: number, p25: number, p50: number, p75: number, correlations?: any | null };

export type CompanyProductFragment = { __typename?: 'CompanyProduct', id: string, properties?: any | null, Product: { __typename?: 'Product', id: string, name: string, order_of_magnitude: number, pricing_type: string, properties?: any | null, ProductUnit?: { __typename?: 'ProductUnit', display_name: string } | null }, Pricings: Array<{ __typename?: 'Pricing', id: string, quantity?: number | null, currency: string, normalized_amount: number, normalized_amount_decimal: string, start_date?: string | null, Segment?: { __typename?: 'PricingSegment', SegmentStats: Array<{ __typename?: 'SegmentStat', min: number, max: number, avg: number, std: number, p25: number, p50: number, p75: number, correlations?: any | null }>, SegmentCondition: Array<{ __typename?: 'SegmentCondition', id: string, property_type: string, property: string, operator: string, value: any }> } | null }> };

export type GetVendorProductsQueryVariables = Exact<{
  vendorId: Scalars['uuid']['input'];
}>;


export type GetVendorProductsQuery = { __typename?: 'query_root', CompanyProduct: Array<{ __typename?: 'CompanyProduct', id: string, properties?: any | null, Product: { __typename?: 'Product', id: string, name: string, order_of_magnitude: number, pricing_type: string, properties?: any | null, ProductUnit?: { __typename?: 'ProductUnit', display_name: string } | null }, Pricings: Array<{ __typename?: 'Pricing', id: string, quantity?: number | null, currency: string, normalized_amount: number, normalized_amount_decimal: string, start_date?: string | null, Segment?: { __typename?: 'PricingSegment', SegmentStats: Array<{ __typename?: 'SegmentStat', min: number, max: number, avg: number, std: number, p25: number, p50: number, p75: number, correlations?: any | null }>, SegmentCondition: Array<{ __typename?: 'SegmentCondition', id: string, property_type: string, property: string, operator: string, value: any }> } | null }> }> };

export type SubscriptionFragment = { __typename?: 'Subscription', id: string, name?: string | null, starts_at?: string | null, ends_at?: string | null, recurring: any, auto_renewal?: boolean | null, notice_period?: any | null, has_extra_contractual_info?: boolean | null, Amount?: { __typename?: 'SubscriptionAmount', annual_amount: number, currency: string } | null };

export type SubscriptionWithProductsFragment = { __typename?: 'Subscription', id: string, name?: string | null, starts_at?: string | null, ends_at?: string | null, recurring: any, auto_renewal?: boolean | null, notice_period?: any | null, has_extra_contractual_info?: boolean | null, Orders: Array<{ __typename?: 'Order', Prices: Array<{ __typename?: 'Price', Product: { __typename?: 'Product', id: string, name: string } }> }>, Amount?: { __typename?: 'SubscriptionAmount', annual_amount: number, currency: string } | null };

export type GetVendorSubscriptionsQueryVariables = Exact<{
  vendorId: Scalars['uuid']['input'];
}>;


export type GetVendorSubscriptionsQuery = { __typename?: 'query_root', Subscription: Array<{ __typename?: 'Subscription', id: string, name?: string | null, starts_at?: string | null, ends_at?: string | null, recurring: any, auto_renewal?: boolean | null, notice_period?: any | null, has_extra_contractual_info?: boolean | null, Orders: Array<{ __typename?: 'Order', Prices: Array<{ __typename?: 'Price', Product: { __typename?: 'Product', id: string, name: string } }> }>, Amount?: { __typename?: 'SubscriptionAmount', annual_amount: number, currency: string } | null }> };

export const CompanyFileFragmentDoc = gql`
    fragment CompanyFile on File {
  Vendor {
    id
    name
    logo
  }
  name
  storage_name
  type
  date
  status
}
    `;
export const VendorFragmentDoc = gql`
    fragment Vendor on CompanyVendor {
  id
  name
  nb_insights
  nb_prices
  Vendor {
    logo
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  name
  status
  storage_name
  type
  date
}
    `;
export const SegmentStatFragmentDoc = gql`
    fragment SegmentStat on SegmentStat {
  min
  max
  avg
  std
  p25
  p50
  p75
  correlations
}
    `;
export const CompanyProductFragmentDoc = gql`
    fragment CompanyProduct on CompanyProduct {
  id
  properties
  Product {
    id
    name
    order_of_magnitude
    pricing_type
    properties
    ProductUnit {
      display_name
    }
  }
  Pricings(order_by: {period: desc}, limit: 1) {
    id
    quantity
    currency
    normalized_amount
    normalized_amount_decimal
    start_date: period_start
    Segment {
      SegmentStats(
        where: {published: {_eq: true}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        ...SegmentStat
      }
      SegmentCondition {
        id
        property_type
        property
        operator
        value
      }
    }
  }
}
    ${SegmentStatFragmentDoc}`;
export const SubscriptionFragmentDoc = gql`
    fragment Subscription on Subscription {
  id
  name
  starts_at
  ends_at
  recurring
  auto_renewal
  notice_period
  has_extra_contractual_info
  Amount {
    annual_amount
    currency
  }
}
    `;
export const SubscriptionWithProductsFragmentDoc = gql`
    fragment SubscriptionWithProducts on Subscription {
  ...Subscription
  Orders {
    Prices {
      Product {
        id
        name
      }
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export const ArchiveVendorDocument = gql`
    mutation ArchiveVendor($vendorId: uuid!) {
  update_InactiveCompanyVendor(
    where: {vendor_id: {_eq: $vendorId}}
    _set: {confirmed: true}
  ) {
    affected_rows
  }
}
    `;
export type ArchiveVendorMutationFn = Apollo.MutationFunction<ArchiveVendorMutation, ArchiveVendorMutationVariables>;

/**
 * __useArchiveVendorMutation__
 *
 * To run a mutation, you first call `useArchiveVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveVendorMutation, { data, loading, error }] = useArchiveVendorMutation({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useArchiveVendorMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveVendorMutation, ArchiveVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveVendorMutation, ArchiveVendorMutationVariables>(ArchiveVendorDocument, options);
      }
export type ArchiveVendorMutationHookResult = ReturnType<typeof useArchiveVendorMutation>;
export type ArchiveVendorMutationResult = Apollo.MutationResult<ArchiveVendorMutation>;
export type ArchiveVendorMutationOptions = Apollo.BaseMutationOptions<ArchiveVendorMutation, ArchiveVendorMutationVariables>;
export const CreateDownloadUrlDocument = gql`
    mutation CreateDownloadUrl($filename: String!) {
  createReadUrl(filename: $filename) {
    url
  }
}
    `;
export type CreateDownloadUrlMutationFn = Apollo.MutationFunction<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>;

/**
 * __useCreateDownloadUrlMutation__
 *
 * To run a mutation, you first call `useCreateDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadUrlMutation, { data, loading, error }] = useCreateDownloadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useCreateDownloadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>(CreateDownloadUrlDocument, options);
      }
export type CreateDownloadUrlMutationHookResult = ReturnType<typeof useCreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationResult = Apollo.MutationResult<CreateDownloadUrlMutation>;
export type CreateDownloadUrlMutationOptions = Apollo.BaseMutationOptions<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>;
export const CreateFileDocument = gql`
    mutation CreateFile($name: String!, $storageName: String!, $vendorId: uuid) {
  insert_File_one(
    object: {name: $name, storage_name: $storageName, vendor_id: $vendorId}
  ) {
    id
  }
}
    `;
export type CreateFileMutationFn = Apollo.MutationFunction<CreateFileMutation, CreateFileMutationVariables>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      storageName: // value for 'storageName'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument, options);
      }
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<CreateFileMutation, CreateFileMutationVariables>;
export const CreateUploadUrlDocument = gql`
    mutation CreateUploadUrl($filename: String!) {
  createCompanyUploadUrl(filename: $filename) {
    url
  }
}
    `;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>(CreateUploadUrlDocument, options);
      }
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = Apollo.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;
export const ReactivateVendorDocument = gql`
    mutation ReactivateVendor($vendorId: uuid!) {
  update_InactiveCompanyVendor(
    where: {vendor_id: {_eq: $vendorId}}
    _set: {confirmed: false}
  ) {
    affected_rows
  }
}
    `;
export type ReactivateVendorMutationFn = Apollo.MutationFunction<ReactivateVendorMutation, ReactivateVendorMutationVariables>;

/**
 * __useReactivateVendorMutation__
 *
 * To run a mutation, you first call `useReactivateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateVendorMutation, { data, loading, error }] = useReactivateVendorMutation({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useReactivateVendorMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateVendorMutation, ReactivateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateVendorMutation, ReactivateVendorMutationVariables>(ReactivateVendorDocument, options);
      }
export type ReactivateVendorMutationHookResult = ReturnType<typeof useReactivateVendorMutation>;
export type ReactivateVendorMutationResult = Apollo.MutationResult<ReactivateVendorMutation>;
export type ReactivateVendorMutationOptions = Apollo.BaseMutationOptions<ReactivateVendorMutation, ReactivateVendorMutationVariables>;
export const ResetSlackConnectionDocument = gql`
    mutation resetSlackConnection {
  resetSlackConnection {
    connected
    error
  }
}
    `;
export type ResetSlackConnectionMutationFn = Apollo.MutationFunction<ResetSlackConnectionMutation, ResetSlackConnectionMutationVariables>;

/**
 * __useResetSlackConnectionMutation__
 *
 * To run a mutation, you first call `useResetSlackConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSlackConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSlackConnectionMutation, { data, loading, error }] = useResetSlackConnectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetSlackConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ResetSlackConnectionMutation, ResetSlackConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetSlackConnectionMutation, ResetSlackConnectionMutationVariables>(ResetSlackConnectionDocument, options);
      }
export type ResetSlackConnectionMutationHookResult = ReturnType<typeof useResetSlackConnectionMutation>;
export type ResetSlackConnectionMutationResult = Apollo.MutationResult<ResetSlackConnectionMutation>;
export type ResetSlackConnectionMutationOptions = Apollo.BaseMutationOptions<ResetSlackConnectionMutation, ResetSlackConnectionMutationVariables>;
export const SetSlackConnectionDocument = gql`
    mutation setSlackConnection($code: String!) {
  setSlackConnection(code: $code) {
    connected
    error
  }
}
    `;
export type SetSlackConnectionMutationFn = Apollo.MutationFunction<SetSlackConnectionMutation, SetSlackConnectionMutationVariables>;

/**
 * __useSetSlackConnectionMutation__
 *
 * To run a mutation, you first call `useSetSlackConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSlackConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSlackConnectionMutation, { data, loading, error }] = useSetSlackConnectionMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetSlackConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SetSlackConnectionMutation, SetSlackConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSlackConnectionMutation, SetSlackConnectionMutationVariables>(SetSlackConnectionDocument, options);
      }
export type SetSlackConnectionMutationHookResult = ReturnType<typeof useSetSlackConnectionMutation>;
export type SetSlackConnectionMutationResult = Apollo.MutationResult<SetSlackConnectionMutation>;
export type SetSlackConnectionMutationOptions = Apollo.BaseMutationOptions<SetSlackConnectionMutation, SetSlackConnectionMutationVariables>;
export const SetSubscriptionExtraInfoDocument = gql`
    mutation SetSubscriptionExtraInfo($subscriptionId: uuid!, $hasExtraInfo: Boolean!, $fileId: uuid) {
  update_Subscription_by_pk(
    pk_columns: {id: $subscriptionId}
    _set: {has_extra_contractual_info: $hasExtraInfo, extra_contractual_file_id: $fileId}
  ) {
    id
  }
}
    `;
export type SetSubscriptionExtraInfoMutationFn = Apollo.MutationFunction<SetSubscriptionExtraInfoMutation, SetSubscriptionExtraInfoMutationVariables>;

/**
 * __useSetSubscriptionExtraInfoMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionExtraInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionExtraInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionExtraInfoMutation, { data, loading, error }] = useSetSubscriptionExtraInfoMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      hasExtraInfo: // value for 'hasExtraInfo'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useSetSubscriptionExtraInfoMutation(baseOptions?: Apollo.MutationHookOptions<SetSubscriptionExtraInfoMutation, SetSubscriptionExtraInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSubscriptionExtraInfoMutation, SetSubscriptionExtraInfoMutationVariables>(SetSubscriptionExtraInfoDocument, options);
      }
export type SetSubscriptionExtraInfoMutationHookResult = ReturnType<typeof useSetSubscriptionExtraInfoMutation>;
export type SetSubscriptionExtraInfoMutationResult = Apollo.MutationResult<SetSubscriptionExtraInfoMutation>;
export type SetSubscriptionExtraInfoMutationOptions = Apollo.BaseMutationOptions<SetSubscriptionExtraInfoMutation, SetSubscriptionExtraInfoMutationVariables>;
export const GetCompanyFilesDocument = gql`
    query getCompanyFiles($where: File_bool_exp, $offset: Int, $limit: Int) {
  File(where: $where, offset: $offset, limit: $limit, order_by: {date: desc}) {
    ...CompanyFile
  }
  File_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${CompanyFileFragmentDoc}`;

/**
 * __useGetCompanyFilesQuery__
 *
 * To run a query within a React component, call `useGetCompanyFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCompanyFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>(GetCompanyFilesDocument, options);
      }
export function useGetCompanyFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>(GetCompanyFilesDocument, options);
        }
export function useGetCompanyFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>(GetCompanyFilesDocument, options);
        }
export type GetCompanyFilesQueryHookResult = ReturnType<typeof useGetCompanyFilesQuery>;
export type GetCompanyFilesLazyQueryHookResult = ReturnType<typeof useGetCompanyFilesLazyQuery>;
export type GetCompanyFilesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyFilesSuspenseQuery>;
export type GetCompanyFilesQueryResult = Apollo.QueryResult<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>;
export const GetCompanyProductDocument = gql`
    query GetCompanyProduct($id: uuid!) {
  CompanyProduct_by_pk(id: $id) {
    ...CompanyProduct
    Vendor {
      id
      name
      logo
    }
  }
  getPricingSamples(company_product_id: $id) {
    pricings {
      normalized_amount
      currency
      quantity
      start_date
    }
  }
}
    ${CompanyProductFragmentDoc}`;

/**
 * __useGetCompanyProductQuery__
 *
 * To run a query within a React component, call `useGetCompanyProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyProductQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyProductQuery, GetCompanyProductQueryVariables> & ({ variables: GetCompanyProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyProductQuery, GetCompanyProductQueryVariables>(GetCompanyProductDocument, options);
      }
export function useGetCompanyProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyProductQuery, GetCompanyProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyProductQuery, GetCompanyProductQueryVariables>(GetCompanyProductDocument, options);
        }
export function useGetCompanyProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyProductQuery, GetCompanyProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyProductQuery, GetCompanyProductQueryVariables>(GetCompanyProductDocument, options);
        }
export type GetCompanyProductQueryHookResult = ReturnType<typeof useGetCompanyProductQuery>;
export type GetCompanyProductLazyQueryHookResult = ReturnType<typeof useGetCompanyProductLazyQuery>;
export type GetCompanyProductSuspenseQueryHookResult = ReturnType<typeof useGetCompanyProductSuspenseQuery>;
export type GetCompanyProductQueryResult = Apollo.QueryResult<GetCompanyProductQuery, GetCompanyProductQueryVariables>;
export const GetCompanyVendorDocument = gql`
    query GetCompanyVendor($id: uuid!, $external_id: String!) {
  CompanyVendor(
    where: {id: {_eq: $id}, Company: {external_id: {_eq: $external_id}}}
  ) {
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;

/**
 * __useGetCompanyVendorQuery__
 *
 * To run a query within a React component, call `useGetCompanyVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyVendorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      external_id: // value for 'external_id'
 *   },
 * });
 */
export function useGetCompanyVendorQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyVendorQuery, GetCompanyVendorQueryVariables> & ({ variables: GetCompanyVendorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>(GetCompanyVendorDocument, options);
      }
export function useGetCompanyVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>(GetCompanyVendorDocument, options);
        }
export function useGetCompanyVendorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>(GetCompanyVendorDocument, options);
        }
export type GetCompanyVendorQueryHookResult = ReturnType<typeof useGetCompanyVendorQuery>;
export type GetCompanyVendorLazyQueryHookResult = ReturnType<typeof useGetCompanyVendorLazyQuery>;
export type GetCompanyVendorSuspenseQueryHookResult = ReturnType<typeof useGetCompanyVendorSuspenseQuery>;
export type GetCompanyVendorQueryResult = Apollo.QueryResult<GetCompanyVendorQuery, GetCompanyVendorQueryVariables>;
export const GetCompanyVendorsToConfirmDocument = gql`
    query GetCompanyVendorsToConfirm {
  CompanyVendor(where: {status: {_eq: "to-confirm"}}) {
    ...Vendor
    ended_at
  }
}
    ${VendorFragmentDoc}`;

/**
 * __useGetCompanyVendorsToConfirmQuery__
 *
 * To run a query within a React component, call `useGetCompanyVendorsToConfirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyVendorsToConfirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyVendorsToConfirmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyVendorsToConfirmQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>(GetCompanyVendorsToConfirmDocument, options);
      }
export function useGetCompanyVendorsToConfirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>(GetCompanyVendorsToConfirmDocument, options);
        }
export function useGetCompanyVendorsToConfirmSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>(GetCompanyVendorsToConfirmDocument, options);
        }
export type GetCompanyVendorsToConfirmQueryHookResult = ReturnType<typeof useGetCompanyVendorsToConfirmQuery>;
export type GetCompanyVendorsToConfirmLazyQueryHookResult = ReturnType<typeof useGetCompanyVendorsToConfirmLazyQuery>;
export type GetCompanyVendorsToConfirmSuspenseQueryHookResult = ReturnType<typeof useGetCompanyVendorsToConfirmSuspenseQuery>;
export type GetCompanyVendorsToConfirmQueryResult = Apollo.QueryResult<GetCompanyVendorsToConfirmQuery, GetCompanyVendorsToConfirmQueryVariables>;
export const GetCompanyVendorsDocument = gql`
    query GetCompanyVendors {
  CompanyVendor(where: {status: {_neq: "inactive"}}) {
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;

/**
 * __useGetCompanyVendorsQuery__
 *
 * To run a query within a React component, call `useGetCompanyVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>(GetCompanyVendorsDocument, options);
      }
export function useGetCompanyVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>(GetCompanyVendorsDocument, options);
        }
export function useGetCompanyVendorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>(GetCompanyVendorsDocument, options);
        }
export type GetCompanyVendorsQueryHookResult = ReturnType<typeof useGetCompanyVendorsQuery>;
export type GetCompanyVendorsLazyQueryHookResult = ReturnType<typeof useGetCompanyVendorsLazyQuery>;
export type GetCompanyVendorsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyVendorsSuspenseQuery>;
export type GetCompanyVendorsQueryResult = Apollo.QueryResult<GetCompanyVendorsQuery, GetCompanyVendorsQueryVariables>;
export const GetCompanyStatsDocument = gql`
    query GetCompanyStats {
  vendors: CompanyVendor_aggregate {
    aggregate {
      count
    }
  }
  files: File_aggregate {
    aggregate {
      count
    }
  }
  subscriptions: Subscription_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCompanyStatsQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>(GetCompanyStatsDocument, options);
      }
export function useGetCompanyStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>(GetCompanyStatsDocument, options);
        }
export function useGetCompanyStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>(GetCompanyStatsDocument, options);
        }
export type GetCompanyStatsQueryHookResult = ReturnType<typeof useGetCompanyStatsQuery>;
export type GetCompanyStatsLazyQueryHookResult = ReturnType<typeof useGetCompanyStatsLazyQuery>;
export type GetCompanyStatsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyStatsSuspenseQuery>;
export type GetCompanyStatsQueryResult = Apollo.QueryResult<GetCompanyStatsQuery, GetCompanyStatsQueryVariables>;
export const GetSlackConnectionStatusDocument = gql`
    query getSlackConnectionStatus {
  SlackConnectionStatus {
    connected
  }
}
    `;

/**
 * __useGetSlackConnectionStatusQuery__
 *
 * To run a query within a React component, call `useGetSlackConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlackConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlackConnectionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSlackConnectionStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>(GetSlackConnectionStatusDocument, options);
      }
export function useGetSlackConnectionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>(GetSlackConnectionStatusDocument, options);
        }
export function useGetSlackConnectionStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>(GetSlackConnectionStatusDocument, options);
        }
export type GetSlackConnectionStatusQueryHookResult = ReturnType<typeof useGetSlackConnectionStatusQuery>;
export type GetSlackConnectionStatusLazyQueryHookResult = ReturnType<typeof useGetSlackConnectionStatusLazyQuery>;
export type GetSlackConnectionStatusSuspenseQueryHookResult = ReturnType<typeof useGetSlackConnectionStatusSuspenseQuery>;
export type GetSlackConnectionStatusQueryResult = Apollo.QueryResult<GetSlackConnectionStatusQuery, GetSlackConnectionStatusQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  Subscription(
    where: {_or: [{ends_at: {_gte: "now()"}}, {ends_at: {_is_null: true}}]}
  ) {
    ...Subscription
    CompanyVendor {
      ...Vendor
    }
    Orders(order_by: {created_at: desc}, limit: 1) {
      billed_period
    }
  }
}
    ${SubscriptionFragmentDoc}
${VendorFragmentDoc}`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export function useGetSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionsSuspenseQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetVendorFilesDocument = gql`
    query GetVendorFiles($vendorId: uuid!) {
  File(where: {vendor_id: {_eq: $vendorId}}, order_by: {date: desc}) {
    ...File
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetVendorFilesQuery__
 *
 * To run a query within a React component, call `useGetVendorFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorFilesQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetVendorFilesQuery(baseOptions: Apollo.QueryHookOptions<GetVendorFilesQuery, GetVendorFilesQueryVariables> & ({ variables: GetVendorFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorFilesQuery, GetVendorFilesQueryVariables>(GetVendorFilesDocument, options);
      }
export function useGetVendorFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorFilesQuery, GetVendorFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorFilesQuery, GetVendorFilesQueryVariables>(GetVendorFilesDocument, options);
        }
export function useGetVendorFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVendorFilesQuery, GetVendorFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorFilesQuery, GetVendorFilesQueryVariables>(GetVendorFilesDocument, options);
        }
export type GetVendorFilesQueryHookResult = ReturnType<typeof useGetVendorFilesQuery>;
export type GetVendorFilesLazyQueryHookResult = ReturnType<typeof useGetVendorFilesLazyQuery>;
export type GetVendorFilesSuspenseQueryHookResult = ReturnType<typeof useGetVendorFilesSuspenseQuery>;
export type GetVendorFilesQueryResult = Apollo.QueryResult<GetVendorFilesQuery, GetVendorFilesQueryVariables>;
export const GetVendorProductsDocument = gql`
    query GetVendorProducts($vendorId: uuid!) {
  CompanyProduct(
    where: {vendor_id: {_eq: $vendorId}}
    order_by: {Product: {name: asc}}
  ) {
    ...CompanyProduct
  }
}
    ${CompanyProductFragmentDoc}`;

/**
 * __useGetVendorProductsQuery__
 *
 * To run a query within a React component, call `useGetVendorProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorProductsQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetVendorProductsQuery(baseOptions: Apollo.QueryHookOptions<GetVendorProductsQuery, GetVendorProductsQueryVariables> & ({ variables: GetVendorProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorProductsQuery, GetVendorProductsQueryVariables>(GetVendorProductsDocument, options);
      }
export function useGetVendorProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorProductsQuery, GetVendorProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorProductsQuery, GetVendorProductsQueryVariables>(GetVendorProductsDocument, options);
        }
export function useGetVendorProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVendorProductsQuery, GetVendorProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorProductsQuery, GetVendorProductsQueryVariables>(GetVendorProductsDocument, options);
        }
export type GetVendorProductsQueryHookResult = ReturnType<typeof useGetVendorProductsQuery>;
export type GetVendorProductsLazyQueryHookResult = ReturnType<typeof useGetVendorProductsLazyQuery>;
export type GetVendorProductsSuspenseQueryHookResult = ReturnType<typeof useGetVendorProductsSuspenseQuery>;
export type GetVendorProductsQueryResult = Apollo.QueryResult<GetVendorProductsQuery, GetVendorProductsQueryVariables>;
export const GetVendorSubscriptionsDocument = gql`
    query GetVendorSubscriptions($vendorId: uuid!) {
  Subscription(where: {vendor_id: {_eq: $vendorId}}) {
    ...SubscriptionWithProducts
  }
}
    ${SubscriptionWithProductsFragmentDoc}`;

/**
 * __useGetVendorSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetVendorSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorSubscriptionsQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetVendorSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables> & ({ variables: GetVendorSubscriptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>(GetVendorSubscriptionsDocument, options);
      }
export function useGetVendorSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>(GetVendorSubscriptionsDocument, options);
        }
export function useGetVendorSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>(GetVendorSubscriptionsDocument, options);
        }
export type GetVendorSubscriptionsQueryHookResult = ReturnType<typeof useGetVendorSubscriptionsQuery>;
export type GetVendorSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetVendorSubscriptionsLazyQuery>;
export type GetVendorSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetVendorSubscriptionsSuspenseQuery>;
export type GetVendorSubscriptionsQueryResult = Apollo.QueryResult<GetVendorSubscriptionsQuery, GetVendorSubscriptionsQueryVariables>;