import * as Sentry from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { useEffect } from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router";
import { User } from "../types";

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.PROD ? "production" : "development",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        traceFetch: false,
      }),
    ],
    beforeBreadcrumb: excludeGraphQLFetch,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      'can\'t redefine non-configurable property "get"',
    ],

    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

function ifSentryConfig(fn: () => void) {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    fn();
  }
}

export function captureException(error: unknown) {
  ifSentryConfig(() => Sentry.captureException(error));
}

export function captureMessage(message: string) {
  ifSentryConfig(() => Sentry.captureMessage(message, "error"));
}

export function captureFetchError(message: string, response: Response) {
  ifSentryConfig(() => {
    Sentry.withScope((scope) => {
      scope.setExtra("response_status", response.status);
      scope.setExtra("url", response.url);

      const error = new Error(message);
      // avoids having a generic 'Error' as the error name.
      error.name = "FetchError";
      Sentry.captureException(error);
    });
  });
}

export function setUser({ id, email, firstName, lastName }: User) {
  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.setUser({ id, email, username: `${firstName} ${lastName}` });
  }
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
