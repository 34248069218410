import { Navigate } from "react-router";
import { useCompanyStats } from "../hooks/useCompanyStats";
import ErrorPage from "./error";

export default function Component() {
  const { nbSubscriptions } = useCompanyStats();

  if (nbSubscriptions === 0) {
    return <Navigate to="/documents" replace />;
  }

  return <Navigate to="/subscriptions" replace />;
}

Component.displayName = "HomePage";

export function ErrorBoundary() {
  return <ErrorPage />;
}

ErrorBoundary.displayName = "HomeErrorBoundary";
